import './App.css';
import React, { useState, useEffect } from "react";

import Audio from './components/Audio'
import Header from './components/Header'
import Carousel from './components/Carousel'
import Navigator from './components/Navigator'
import Separator from './components/Separator'
import Cards from './components/Cards'

function App() {
  const underConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION;

  const homePageAssets = {
    backsound: process.env.PUBLIC_URL + '/media/backsound.mp3',
    logo: process.env.PUBLIC_URL + '/common/logo.png',
    background: process.env.PUBLIC_URL + '/common/background.png',
    slidesPerView: 3,
    separator: process.env.PUBLIC_URL + '/common/separator.png',
    separatorIcon: process.env.PUBLIC_URL + '/common/separator-icon.png',
    separatorLine: process.env.PUBLIC_URL + '/common/separator-line.png',
    hamburgerLogo: process.env.PUBLIC_URL + '/icon/icon-hamburger.svg',
    closeLogo: process.env.PUBLIC_URL + '/icon/icon-close.svg',
    discordUrl: 'https://discord.gg/Kh4ggAG5HD',
    xUrl: 'https://x.com/chancannfts',
    rarityLogo: process.env.PUBLIC_URL + '/common/rarity-logo.png',
  };

  const carouselItems = [
    {
      url: homePageAssets.discordUrl,
      image: process.env.PUBLIC_URL + '/contents/carousel-discord.png',
    },
    {
      url: "#chan-map",
      image: process.env.PUBLIC_URL + '/contents/carousel-roadmap.png',
    },
    {
      url: "#sneak-peek",
      image: process.env.PUBLIC_URL + '/contents/carousel-sneak-peek.png',
    },
    {
      url: homePageAssets.xUrl,
      image: process.env.PUBLIC_URL + '/contents/carousel-x.png',
    }
  ];

  const mainNavigatorItems = [
    { id: 1,
      url: homePageAssets.discordUrl,
      image: process.env.PUBLIC_URL + '/contents/menu-discord.png',
      target: "_blank"
    },
    { id: 2,
      url: homePageAssets.xUrl,
      image: process.env.PUBLIC_URL + '/contents/menu-x.png',
      target: "_blank"
    },
  ];

  const categoryNavigatorItems = [
    { id: 1,
      image: process.env.PUBLIC_URL + '/common/about-us.png',
      hover: process.env.PUBLIC_URL + '/common/about-us-hover.png',
      url: "#about-us"
    },
    { id: 2,
      image: process.env.PUBLIC_URL + '/common/chan-map.png',
      hover: process.env.PUBLIC_URL + '/common/chan-map-hover.png',
      url: "#chan-map"
    },
    { id: 3,
      image: process.env.PUBLIC_URL + '/common/hmu.png',
      hover: process.env.PUBLIC_URL + '/common/hmu-hover.png',
      url: "#hmu"
    },
    { id: 4,
      image: process.env.PUBLIC_URL + '/common/mint-info.png',
      hover: process.env.PUBLIC_URL + '/common/mint-info-hover.png',
      url: "#mint-info"
    },
    { id: 5,
      image: process.env.PUBLIC_URL + '/common/sneak-peek.png',
      hover: process.env.PUBLIC_URL + '/common/sneak-peek-hover.png',
      url: "#sneak-peek"
    },
    { id: 6,
      image: process.env.PUBLIC_URL + '/common/team.png',
      hover: process.env.PUBLIC_URL + '/common/team-hover.png',
      url: "#team"
    }
  ];

  const categoryContents = {
    "about-us": [
      {
        description: "ChanCan is an NFT project on the Solana network that emphasizes hand-drawn art in a chibi anime style. The founders are inspired by anime and cartoon.<br/><br/>In the early stages, ChanCan project is about pfp, music, parody, & game-me. ChanCan NFTs will play a crucial role within the ChanCan ecosystem.<br/><br/>Vision:<br/>Generate a thousand happiness through art, music, meme, and game-me.<br/><br/>\"Keep it simple, keep it super!\""
      }
    ],
    "chan-map": [
      {
        image: process.env.PUBLIC_URL + '/contents/card-roadmap.png'
      }
    ],
    "hmu": [
      {
        description: "<a class='text-blue-700' href='"+homePageAssets.xUrl+"' target='_blank'>X Official Page</a>"
      }
    ],
    "mint-info": [
      {
        description: "We aiming via Magic Eden launchpad, still waiting ME team response.<br/><b>Size: TBD<br/>Price: TBD<br/>Date: Mid/late Oct 2024</b>"
      }
    ],
    "sneak-peek": [
      {
        image: process.env.PUBLIC_URL + '/contents/sneak-peek-1.png'
      },
      {
        image: process.env.PUBLIC_URL + '/contents/sneak-peek-2.png'
      },
      {
        image: process.env.PUBLIC_URL + '/contents/sneak-peek-3.png'
      }
    ],
    "team": [
      {
        image: process.env.PUBLIC_URL + '/contents/team-sound-artist.png',
        subtitle: "AkungChan",
        title: "Founder and Composer",
      },
      {
        image: process.env.PUBLIC_URL + '/contents/team-project-lead.png',
        subtitle: "BispakChan",
        title: "Community Manager",
      },
      {
        image: process.env.PUBLIC_URL + '/contents/team-artist.png',
        subtitle: "AmeChan",
        title: "Artist",
      },
      {
        image: process.env.PUBLIC_URL + '/contents/team-developer.png',
        subtitle: "RaiChan",
        title: "Developer",
      }
    ],
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [hideWelcomePage, setHideWelcomePage] = useState("fixed")

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  if (width < 768) {
    homePageAssets.slidesPerView = 1;
  } else if (width < 1024) {
    homePageAssets.slidesPerView = 2;
  }

  if (underConstruction == 0) {
    return (
      <div className="relative App h-auto font-chansmall antialiased">
        <div className={`${hideWelcomePage} w-full h-screen bg-blue-200 bg-opacity-85 z-50 flex flex-col justify-center align-center`}>
          <button className="rounded-lg px-4 py-2 text-white font-bold bg-blue-300 m-auto hover:bg-blue-500 border-white border-2" onClick={() => setHideWelcomePage("hidden")}>Open Chancan</button>
        </div>
        <Header 
          logo= {homePageAssets.logo}
          hamburgerLogo= {homePageAssets.hamburgerLogo}
          closeLogo= {homePageAssets.closeLogo}
          navigatorMenu= {mainNavigatorItems}
        />
        <Carousel 
          list={carouselItems}
          slidesPerView={homePageAssets.slidesPerView}
        />
        <div className="m-auto content w-10/12 lg:w-5/12" >
        <div className="mt-8" />
          <div className="flex flex-col gap-8">
            <Navigator
              list ={mainNavigatorItems}
              size = "large"
            />
            <Separator 
              separator ={homePageAssets.separator} 
            />
            <Navigator 
              list ={categoryNavigatorItems}
            />
            <Cards 
              list ={categoryContents}
            />
            <img className="w-full" src={homePageAssets.separatorLine} />
            <p>
              Partner:
            </p>
            <a className="m-auto w-24 h-auto hover:scale-110" target="_blank" href="https://raritysniper.com/nft-drops-calendar">
              <img src={homePageAssets.rarityLogo} />
            </a>
          </div>
        </div>
        <div className="mt-8" />
        <footer className="w-full h-auto flex flex-col align-center justify-center bg-blue-300 py-8 text-white">
          <p>
            Chancan<br/>
            Copyright 2024
          </p>
        </footer>
        <Audio 
          backsound ={homePageAssets.backsound}
        />
      </div>
    );
  }

  return (
    <div className="App w-full h-screen flex flex-col justify-center align-center font-chansmall antialiased">
      <div className="w-1/3 h-auto m-auto gap-8 flex flex-col align-center">
        <div className="w-auto h-auto p-8 bg-blue-500 bg-opacity-50 text-white font-bold text-4xl rounded-lg shadow-lg hover:scale-110">
          THIS SITE IS UNDER CONSTRUCTION
        </div>
        <a className="m-auto w-24 h-auto hover:scale-110" href="https://raritysniper.com/nft-drops-calendar">
          <img src={homePageAssets.rarityLogo} />
        </a>
      </div>
    </div>
  );
}

export default App;
